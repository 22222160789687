import React, { useEffect, useState } from 'react';
import Header from './Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../AppStyles.css';
import './SelectionPage.css';

const SelectionPage = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const navigate = useNavigate();
  const email = localStorage.getItem('email') || "n/a"
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedHeadlines, setSelectedHeadlines] = useState([]);
  const [showTitles, setShowTitles] = useState(false);
  const [headlines, setHeadlines] = useState({});
  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const sources = [
    "ABC News",
    "Associated Press",
    "BBC News",
    "Business Insider",
    "CNN",
    "Financial Post",
    "Fortune",
    "Yahoo Finance",
    "New York Times",
    "Financial Times",
    "CoinDesk",
    "Barron's",
    "Decrypt",
    "BeInCrypto",
  ];

  const handleChipClick = (source) => {
    if (selectedSources.includes(source)) {
      setSelectedSources(selectedSources.filter(s => s !== source));
    } else {
      setSelectedSources([...selectedSources, source]);
    }
  };

  const handleHeadlineChipClick = (headlineId) => {
    if (selectedHeadlines.includes(headlineId)) {
      setSelectedHeadlines(selectedHeadlines.filter(id => id !== headlineId));
    } else {
      setSelectedHeadlines([...selectedHeadlines, headlineId]);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    axios.post(`${API_URL}/api/search`, { sources: selectedSources })
      .then(response => {
        let data = response.data;
        // TODO: properly handle updated response format
        let tempHeadlineData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value.map(item => ({ title: item.name, url: item.url }))

          ])
        );

        let tempData = Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value.map(item => item.name)
          ])
        );

        setRecentSearchResults(tempData); // Set recent search results for generator
        let currID = 1;
        let newHeadlines = {};

        Object.keys(tempHeadlineData).forEach(function(key){
          newHeadlines[key] = tempHeadlineData[key].map((item) => ({
            id: currID++,
            title: item.title,
            url: item.url
          }));
        });

        console.log(newHeadlines);
        setHeadlines(newHeadlines);
        setIsLoading(false);
      });
    setShowTitles(true); // Instead of navigating, we'll just show the titles
  };

  const handleLinkClick = (event) => {
    event.stopPropagation(); // Helps us deal with the overlap of clickables
  };

  const navigateUnsignedUsers = () => {
    if (email === 'n/a' || null) {
      navigate('/');
    }
  }

  useEffect(() => {
    console.log("EMAIL: " + email);
    navigateUnsignedUsers();
  }, []);


  const goToReportsPage = () => {
    setIsLoading(true); // Set loading state to true

    console.log('going do reports');
    // Check to see if any headlines were selected
    if (selectedHeadlines.length >= 1) {
      // Getting list of headlines we want
      let allHeadlines = Object.values(recentSearchResults).flat();
      const headlinesToKeep = selectedHeadlines.map(idx => allHeadlines[idx - 1]);
      console.log(headlinesToKeep);

      // Removing other headlines from search results
      for (let key in recentSearchResults) {
        recentSearchResults[key] = recentSearchResults[key].filter(headline => headlinesToKeep.includes(headline));
        if (recentSearchResults[key].length === 0) {
          delete recentSearchResults[key];
        }
      }
      console.log(recentSearchResults);

      const dataToSend = {
        headlines: recentSearchResults,
        user_email: email,
        language: "english"
      };

      const dataToSendChinese = {
        headlines: recentSearchResults,
        user_email: email,
        language: "chinese"
      }

      axios.post(`${API_URL}/api/generator`, dataToSend)
      .then(response => {
        if (response.data.length === 0) {
          setIsLoading(false);

        }
        localStorage.setItem('reports', JSON.stringify(response.data));
        // Make the second API call here
        return axios.post(`${API_URL}/api/generator`, dataToSendChinese)
      })
      .then(response => {
        // Handle the response from the second API call
        console.log(response.data);
        localStorage.setItem('reports_chinese', JSON.stringify(response.data));
        navigate('/report'); // Navigate to the reports page
      });
    } else {
      // No headlines selected!
      console.log("NO HEADLINES SELECTED");
      setIsLoading(false); // Reset loading state if no headlines selected
    }
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <div className="loading-icon">
          <div className="spinner"></div>
          <p>Generating...</p>
        </div>
      ) : (
        <div className="source-content">
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2 style={{ fontWeight: 'bold' }}>Select Sources</h2>
            <div className="chip-container">
              {sources.map(source => (
                <div
                  key={source}
                  className={`chip ${selectedSources.includes(source) ? 'chip-selected' : ''}`}
                  onClick={() => handleChipClick(source)}
                >
                  {source}
                </div>
              ))}
            </div>
            <button onClick={handleSubmit}>Next</button>
          </div>
          {showTitles && (
            <div className="headline-selection">
              <h1 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>Select Headlines</h1>
              {selectedSources.map(source => (
                <div key={source}>
                  <h2>{source}</h2>
                  <div className="headline-chip-container">
                    {headlines[source] && headlines[source].length > 0 ? (
                      headlines[source].map((headline) => (
                        <div
                          key={headline.id}
                          className={`chip ${selectedHeadlines.includes(headline.id) ? 'chip-selected' : ''}`}
                          onClick={() => handleHeadlineChipClick(headline.id)}
                        >
                          {selectedHeadlines.includes(headline.id) && <span className="chip-icon">✓</span>}
                          {headline.title}
                          <a style={{display: "table-cell"}} target="_blank" href={headline.url} onClick={handleLinkClick} rel="noreferrer">Link</a>
                        </div>
                      ))
                    ) : (
                      <p>Sorry, no headlines available</p>
                    )}
                  </div>
                </div>
              ))}
              <button onClick={goToReportsPage} style={{ marginTop: '20px' }}>Next</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectionPage;
