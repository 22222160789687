import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import Header from './Header'; // Import the Header component

const ReportPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const email = localStorage.getItem('email') || 'n/a';
  const [language, setLanguage] = useState('english');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [generatedReport, setGeneratedReport] = useState('');
  const [translatedReport, setTranslatedReport] = useState('');
  const [timestamps, setTimestamps] = useState([]); // State for storing timestamps
  const [selectedTimestamp, setSelectedTimestamp] = useState(''); // State for the selected timestamp
  const excludedKeys = [
    'description',
    'gpt',
    'date_generated',
    'query',
    'language',
    'category',
  ];
  const noBulletPointKeys = [
    'summary',
  ]

  const createGeneratedReport = (reports) => {
    if (reports.length === 0) {
      return 'Error\nNo reports found';
    }

    let generatedReport = '';
    reports.forEach((report, reportIndex) => {
      // add title section at beginning of report
      let titleCurr = '';
      if (Object.keys(report).length <= 1) {
        titleCurr = "Failed to generate report for this article"
      } else {
        titleCurr = [
          `Description: ${report.description}`,
          `Category: ${report.category}`,
          `Link: ${report.query?.url}`
        ].join('\t');
      }
      const title = report.query?.name || 'Error';
      const reportWithTitle = { [title]: titleCurr, ...report };

      Object.keys(reportWithTitle).forEach(key => {
        if (excludedKeys.includes(key)) return; // Skip excluded keys

        let curr = reportWithTitle[key];
        let items;

        if (noBulletPointKeys.includes(key)) {
          items = curr;
        } else if (curr.includes('\t')) {
            items = curr.split('\t').map(item => `• ${item}`).join('\n');
        } else {
          items = `• ${curr}`;
        }

        if (key !== title) {
          key = key.replace('_', ' ').replace(/\b\w/g, (char) => char.toUpperCase());
        }

        generatedReport += `${key}\n${items}\n\n`;
      });

      if (reportIndex < reports.length - 1) {
        generatedReport += '\n\n'; // Separate different reports
      }
    });

    // console.log("Generated Report:");
    // console.log(generatedReport);

    return generatedReport;
  };

  const generatePdf = () => {
    let reports;
    const doc = new jsPDF();
    const fontSize = 10; // Set the font size to a smaller value
    doc.setFontSize(fontSize);
    if (language === 'english') {
      doc.addFont("../NotoSerif.ttf", "NotoSerif", "normal");
      doc.setFont("NotoSerif", "normal"); // set font
      reports = JSON.parse(localStorage.getItem('reports')); // Grab reports from local storage
      setTranslatedReport(''); // Clear translated report when generating English report
    } else {
      doc.addFont("../NotoSansSC.ttf", "NotoSansSC", "normal");
      doc.setFont("NotoSansSC", "normal"); // set font
      reports = JSON.parse(localStorage.getItem('reports_chinese'));
    }
  
    let generatedReport = createGeneratedReport(reports);
    let reportSections = generatedReport.split('\n\n\n\n');
  
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const maxHeight = pageHeight - 2 * margin;
  
    reportSections.forEach((section, index) => {
      if (index > 0) {
        doc.addPage(); // Add a new page for each new section except the first one
      }
  
      let lines = doc.splitTextToSize(section, 180);
      let cursorY = margin;
  
      lines.forEach((line) => {
        if (cursorY + doc.getTextDimensions(line).h > maxHeight) {
          doc.addPage();
          cursorY = margin;
        }
        doc.text(line, 10, cursorY);
        cursorY += doc.getTextDimensions(line).h + 2;
      });
    });
  
    // Create a Blob from the PDF and generate a Blob URL
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
  
    localStorage.setItem('generated_report', generatedReport);
    setGeneratedReport(generatedReport);
  
    if (language === 'chinese') {
      setTranslatedReport(generatedReport); // Set translated report
    }
  };
  


  const navigateUnsignedUsers = () => {
    if (email === 'n/a' || email === null) {
      navigate('/');
    }
  };

  useEffect(() => {
    console.log('EMAIL: ' + email);
    navigateUnsignedUsers();
    generatePdf(); // Call generatePdf with default "english" language
    viewPastReports(); // Fetch old reports
    // Clean up the Blob URL when the component unmounts
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);

  useEffect(() => {
    generatePdf(); // Generate PDF whenever the language changes
  }, [language]);

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'report.pdf';
    link.click();
  };

  const viewPastReports = async () => {
    try {
      console.log(email);
      const response = await fetch(`${API_URL}/api/past_reports/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: email }), // Pass user_email to the API
      });
      const data = await response.json();
      // Debugging statement to see the data returned from the API
      console.log('API response data:', data);
      // Check if data.reports exists and is an array
      if (Array.isArray(data.timestamps)) {
        // Extract timestamps from the reports array
        const timestamps = data.timestamps;
        console.log('Extracted timestamps:', timestamps);
        setTimestamps(timestamps);
      } else {
        console.error('Invalid data format for reports:', data.reports);
      }
    } catch (error) {
      console.error('Error fetching past reports:', error);
    }
  };

  const handleTimestampChange = (event) => {
    setSelectedTimestamp(event.target.value);
    // Fetch and display the report for the selected timestamp
    fetchReportByTimestamp(event.target.value);
  };

  const fetchReportByTimestamp = async (timestamp) => {
    try {
      const response = await fetch(`${API_URL}/api/past_reports/single`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: email, timestamp }), // Pass user_email and timestamp to the API
      });
      const data = await response.json();
      // Debugging statement to see the report data returned from the API
      console.log('Report data:', data);

      // Construct the report content from the response
      let generatedReport = createGeneratedReport(data);

      setGeneratedReport(generatedReport); // Update the generated report with the selected timestamp's report
    } catch (error) {
      console.error('Error fetching report by timestamp:', error);
    }
  };

  const toggleLanguage = () => {
    if (language === 'chinese') {
      setLanguage('english');
    } else {
      setLanguage('chinese');
    }
  };

  const renderReportContent = () => {
    return generatedReport.split('\n\n\n\n').map((article, articleIndex) => {
      // console.log(`Article ${articleIndex}:`);
      return (
        <div key={`article-${articleIndex}`} style={{ marginBottom: '40px' }}>
          {article.split('\n\n').map((section, sectionIndex) => {
            // console.log(`  Section ${sectionIndex}:`);
            return (
              <div key={`section-${articleIndex}-${sectionIndex}`} style={{ marginBottom: '20px' }}>
                {section.split('\n').map((line, lineIndex) => {
                  // console.log(`    Line ${lineIndex}: ${line}`);

                  // Title formatting for the first line of the first section of every article
                  if (sectionIndex === 0 && lineIndex === 0) {
                    // console.log('    (Title formatting applied)');
                    return (
                      <p
                        key={`title-${articleIndex}-${sectionIndex}-${lineIndex}`}
                        style={{
                          fontFamily: language === 'chinese' ? 'Noto Sans SC' : 'Serif',
                          fontWeight: 'bold',
                          textAlign: 'left',
                          fontSize: '24px',
                        }}
                      >
                        {line}
                      </p>
                    );
                  }
                  // Subtitle formatting for the first line of second and subsequent sections
                  else if (sectionIndex !== 0 && lineIndex === 0) {
                    // console.log('    (Subtitle formatting applied)');
                    return (
                      <p
                        key={`subtitle-${articleIndex}-${sectionIndex}-${lineIndex}`}
                        style={{
                          margin: 0,
                          textAlign: 'left',
                          fontSize: '18px',
                          fontFamily: language === 'chinese' ? 'Noto Sans SC' : 'Serif',
                          fontWeight: 'bold'
                        }}
                      >
                        {line}
                      </p>
                    );
                  }
                  // Default formatting for all other lines
                  else {
                    // console.log('    (Default formatting applied)');
                    return (
                      <p
                        key={`text-${articleIndex}-${sectionIndex}-${lineIndex}`}
                        style={{
                          margin: 0,
                          textAlign: 'left',
                          fontSize: '14px',
                          fontFamily: language === 'chinese' ? 'Noto Sans SC' : 'Serif',
                        }}
                      >
                        {line}
                      </p>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div style={{ marginLeft: '25%', textAlign: 'left' }}>
      <Header /> {/* Include the Header component here */}
      <h1 style={{ fontWeight: 'bold', textAlign: 'left' }}>Your Report Today</h1>

      {/* Render the report content directly */}
      <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', textAlign: 'left' }}>
        {renderReportContent()}
      </div>

      {/* Dropdown for selecting timestamps */}
      <div style={{ marginTop: '20px' }}>
        <label htmlFor='timestamps'>Select a past report:</label>
        <select id='timestamps' value={selectedTimestamp} onChange={handleTimestampChange}>
          <option value=''>Select a timestamp</option>
          {timestamps.length > 0 ? (
            timestamps.map((timestamp, index) => (
              <option key={index} value={timestamp}>
                {timestamp}
              </option>
            ))
          ) : (
            <option value=''>No timestamps available</option>
          )}
        </select>
      </div>

      {/* Buttons */}
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
        <button onClick={downloadPdf} style={{ marginRight: '10px' }}>
          Download as PDF
        </button>
        <button onClick={toggleLanguage} style={{ marginRight: '10px' }}>
          ENG / 中文
        </button>
      </div>
    </div>
  );
};

export default ReportPage;
