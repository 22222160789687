import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css'; // Ensure this path is correct

const Header = () => {
  const navigate = useNavigate();

  return (
    <nav className="sidebar">
      <ul className="nav-links">
        {/* <li><Link to="/dashboard">Dashboard</Link></li> */}
        <li><Link to="/selection">Selection</Link></li>
        {/* <li><Link to="/title-selection">Title Selection</Link></li> */}
        <li><Link to="/report">Report</Link></li>
        {/* <li><Link to="/template-selection">Template Selection</Link></li> */}

      </ul>
      {/* Adding the logout button at the bottom */}
      <button
        className="logout-btn"
        style={{ position: 'absolute', bottom: '7%', left: '50%', transform: 'translateX(-50%)' }}
        onClick={() => {
          localStorage.setItem("email", "n/a");
          navigate('/')}} // Navigate to landing page
      >
        Logout
      </button>
    </nav>
  );
};

export default Header;
