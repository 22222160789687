import React, { createContext, useState, useContext } from 'react';

// Create a context with default value
const UserContext = createContext({ email: '', setEmail: () => {} });

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// Provider component
export const UserProvider = ({ children }) => {
  const [email, setEmail] = useState('');

  return (
    <UserContext.Provider value={{ email, setEmail }}>
      {children}
    </UserContext.Provider>
  );
};