import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SelectionPage from './components/SelectionPage';
import ReportPage from './components/ReportPage';
import LandingPage from './components/LandingPage';
import SignUpPage from './components/SignUpPage';
import PreferencesPage from './components/PreferencesPage';
import './AppStyles.css';
import './App.css';
import './styling.css';
import './AppLayout.css';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { UserProvider } from './UserContext'; // Import UserProvider

// Register the components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const clientId = '1009494035162-h4ttpresrjvrqa6ek70ilkuqd7jhv7sd.apps.googleusercontent.com';

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <UserProvider> {/* Wrap the Router with UserProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/selection" element={<SelectionPage />} />
          </Routes>
        </Router>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
