import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logoImage from '../SHITTY_MONEY_title_page.png';
import './LandingPage.css';
import { GoogleLogin } from '@react-oauth/google';
import { useUser } from '../UserContext'; // Adjust the import based on your file structure

const LandingPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [ email, setEmail ] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sign in with', { name, email });
    setEmail(email);
    localStorage.setItem('email', email); 
    axios.get(`${API_URL}/api/auth/` + email)
      .then(response => {
        navigate('/selection'); // Navigate to the reports page
      })
      .catch(error => {
        if (error.response.status === 404) {
          setError('User does not exist. Please sign up.');
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  };

  const handleSuccess = (response) => {
    console.log('Login Success:', response);
    // Decode JWT to extract email
    const decodeJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    };

    const decodedToken = decodeJwt(response.credential);
    const email = decodedToken.email;
    console.log('Extracted email:', email);
    setEmail(email);
    localStorage.setItem('email', email );
    axios.get(`${API_URL}/api/auth/` + email)
      .then(response => {
        navigate('/selection'); // Navigate to the reports page
      })
      .catch(error => {
        if (error.response.status === 404) {
          setError('User does not exist. Please sign up.');
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  };

  const handleFailure = (response) => {
    console.log('Login Failed:', response);
    setError('Google login failed. Please try again.');
  };

  const handleSignUp = () => {
    if (!email) {
      setError('Please enter your email to sign up.');
      return;
    }
    axios.post(`${API_URL}/api/auth/` + email)
      .then(response => {
        localStorage.setItem('email', email); 
        navigate('/selection'); // Navigate to the selection page
      })
      .catch(error => {
        if (error.response.status === 409) {
          setError('User already exists. Please sign in.');
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#2b2b2b' }}>
      <h1>
        <img src={logoImage} alt="Home Page Title" style={{ maxWidth: '55%', height: 'auto', display: 'block', margin: '0 auto' }} />
      </h1>
      <form className="signup-box" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleFailure}
          />
          <div style={{ margin: '10px 0', color: 'white' }}>Or</div>
        </div>
        {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} style={{ marginBottom: '10px' }} />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: '10px' }} />
        <button type="submit" style={{ marginBottom: '10px' }}>Sign In</button>
        
      </form>
      <button onClick={handleSignUp} style={{ margin: '20px', padding: '10px', border: '1px solid green', borderRadius: '5px', textDecoration: 'none', color: 'white' , background: 'green'}}>Sign Up</button>
    </div>
  );
};

export default LandingPage;
