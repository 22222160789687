import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignUpPage.css';
import logoImage from '../SHITTY_MONEY_title_page.png';

const SignUpPage = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Change the background color when the component mounts
    document.body.style.backgroundColor = '#2b2b2b';

    // Revert the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sign Up with', { name, email });
    axios.post(`${API_URL}/api/auth/` + email)
    .then(response => {
      navigate('/selection'); // Navigate to the reports page
    }); 
  };

  return (
    <div className="signup-container">
      <form className="signup-box" onSubmit={handleSubmit}>
        <h1 style={{ color: 'white' }}>Welcome to</h1> 
        <h1>
          <img src={logoImage} alt="Home Page Title" style={{ maxWidth: '65%', height: 'auto', display: 'block', margin: '0 auto' }} />
        </h1>
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUpPage;
